// export const roles = [
//   { code: 1, label: "superadmin" },
//   { code: 2, label: "dpd" },
//   { code: 3, label: "dpc" },
//   { code: 5, label: "dpra" },
//   { code: 4, label: "suksesor" },
// ];

export const roles = [
  { code: 1, label: "superadmin" },
  { code: 2, label: "dpd" },
  { code: 3, label: "dpc" },
  { code: 5, label: "dpra" },
  { code: 4, label: "sukkota" },
  { code: 6, label: "sukkec" },
  { code: 7, label: "sukkel" },
  { code: 8, label: "suktps" },
  { code: 9, label: "sukrw" },
  { code: 10, label: "sukrt" },
];

export const statuss = [
  { code: 1, label: "Calon Pemilih" },
  { code: 2, label: "DPT" },
];

export const kets = [
  { code: 1, label: "JAM" },
  { code: 2, label: "Gerindra" },
  { code: 3, label: "PKS" },
  { code: 4, label: "PKH" },
  { code: 5, label: "PAUS" },
];

export const kecamatans = [
  { code: 1, label: "Serang" },
  { code: 2, label: "Cipocok Jaya" },
  { code: 3, label: "Curug" },
  { code: 4, label: "Kasemen" },
  { code: 5, label: "Walantaka" },
  { code: 6, label: "Taktakan" },
];

export const datas = [
  { code: 1, label: "Serang", kel: 12, tps: 328, dpt: 165569 },
  { code: 2, label: "Cipocok Jaya", kel: 8, tps: 137, dpt: 73630 },
  { code: 3, label: "Curug", kel: 10, tps: 83, dpt: 44968 },
  { code: 4, label: "Kasemen", kel: 10, tps: 155, dpt: 79252 },
  { code: 5, label: "Walantaka", kel: 14, tps: 141, dpt: 75900 },
  { code: 6, label: "Taktakan", kel: 13, tps: 148, dpt: 74532 },
];

export const kelurahans = [
  {
    code: 67,
    label: "Kalanganyar",
    kec: "6",
  },
  {
    code: 66,
    label: "Panggungjati",
    kec: "6",
  },
  {
    code: 65,
    label: "Sayar",
    kec: "6",
  },
  {
    code: 64,
    label: "Cibendung",
    kec: "6",
  },
  {
    code: 63,
    label: "Umbul Tengah",
    kec: "6",
  },
  {
    code: 62,
    label: "Taman Baru",
    kec: "6",
  },
  {
    code: 61,
    label: "Pancur",
    kec: "6",
  },
  {
    code: 60,
    label: "Cilowong",
    kec: "6",
  },
  {
    code: 59,
    label: "Kuranji",
    kec: "6",
  },
  {
    code: 58,
    label: "Drangong",
    kec: "6",
  },
  {
    code: 57,
    label: "Lialang",
    kec: "6",
  },
  {
    code: 56,
    label: "Sepang",
    kec: "6",
  },
  {
    code: 55,
    label: "Taktakan",
    kec: "6",
  },
  {
    code: 54,
    label: "Kalodran",
    kec: "5",
  },
  {
    code: 53,
    label: "Nyapah",
    kec: "5",
  },
  {
    code: 52,
    label: "Pager Agung",
    kec: "5",
  },
  {
    code: 51,
    label: "Kiara",
    kec: "5",
  },
  {
    code: 50,
    label: "Pangampelan",
    kec: "5",
  },
  {
    code: 49,
    label: "Tegalsari",
    kec: "5",
  },
  {
    code: 48,
    label: "Teritih",
    kec: "5",
  },
  {
    code: 47,
    label: "Lebakwangi",
    kec: "5",
  },
  {
    code: 46,
    label: "Kapuren",
    kec: "5",
  },
  {
    code: 45,
    label: "Pasuluhan",
    kec: "5",
  },
  {
    code: 44,
    label: "Cigoong",
    kec: "5",
  },
  {
    code: 43,
    label: "Pipitan",
    kec: "5",
  },
  {
    code: 42,
    label: "Pabuaran",
    kec: "5",
  },
  {
    code: 41,
    label: "Walantaka",
    kec: "5",
  },
  {
    code: 40,
    label: "Sawah Luhur",
    kec: "4",
  },
  {
    code: 39,
    label: "Mesjid Priyayi",
    kec: "4",
  },
  {
    code: 38,
    label: "Kilasah",
    kec: "4",
  },
  {
    code: 37,
    label: "Bendung",
    kec: "4",
  },
  {
    code: 36,
    label: "Terumbu",
    kec: "4",
  },
  {
    code: 35,
    label: "Warung Jaud",
    kec: "4",
  },
  {
    code: 34,
    label: "Margaluyu",
    kec: "4",
  },
  {
    code: 33,
    label: "Banten",
    kec: "4",
  },
  {
    code: 32,
    label: "Kasunyatan",
    kec: "4",
  },
  {
    code: 31,
    label: "Kasemen",
    kec: "4",
  },
  {
    code: 30,
    label: "Curug Manis",
    kec: "3",
  },
  {
    code: 29,
    label: "Kemanisan",
    kec: "3",
  },
  {
    code: 28,
    label: "Cilaku",
    kec: "3",
  },
  {
    code: 27,
    label: "Sukawana",
    kec: "3",
  },
  {
    code: 26,
    label: "Tinggar",
    kec: "3",
  },
  {
    code: 25,
    label: "Sukajaya",
    kec: "3",
  },
  {
    code: 24,
    label: "Cipete",
    kec: "3",
  },
  {
    code: 23,
    label: "Pancalaksana",
    kec: "3",
  },
  {
    code: 22,
    label: "Sukalaksana",
    kec: "3",
  },
  {
    code: 21,
    label: "Curug",
    kec: "3",
  },
  {
    code: 20,
    label: "Banjar Sari",
    kec: "2",
  },
  {
    code: 19,
    label: "Tembong",
    kec: "2",
  },
  {
    code: 18,
    label: "Gelam",
    kec: "2",
  },
  {
    code: 17,
    label: "Dalung",
    kec: "2",
  },
  {
    code: 16,
    label: "Banjar Agung",
    kec: "2",
  },
  {
    code: 15,
    label: "Karundang",
    kec: "2",
  },
  {
    code: 14,
    label: "Panancangan",
    kec: "2",
  },
  {
    code: 13,
    label: "Cipocok Jaya",
    kec: "2",
  },
  {
    code: 12,
    label: "Sukawana",
    kec: "1",
  },
  {
    code: 11,
    label: "Terondol",
    kec: "1",
  },
  {
    code: 10,
    label: "Kaligandu",
    kec: "1",
  },
  {
    code: 9,
    label: "Cimuncang",
    kec: "1",
  },
  {
    code: 8,
    label: "Sumur Pecung",
    kec: "1",
  },
  {
    code: 7,
    label: "Unyur",
    kec: "1",
  },
  {
    code: 6,
    label: "Lopang",
    kec: "1",
  },
  {
    code: 5,
    label: "Cipare",
    kec: "1",
  },
  {
    code: 4,
    label: "Lontarbaru",
    kec: "1",
  },
  {
    code: 3,
    label: "Kota Baru",
    kec: "1",
  },
  {
    code: 2,
    label: "Kagungan",
    kec: "1",
  },
  {
    code: 1,
    label: "Serang",
    kec: "1",
  },
];

export const tpss = () => {
  let res = [];
  for (let i = 1; i <= 99; i++) {
    res.push({ code: `${i}`, label: i < 10 ? `0${i}` : `${i}` });
  }
  return res;
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];
