import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { useUserStore } from "./store";
import { useParams } from "react-router-dom";
import TextInput from "../../../Components/Input/TextInput";
import InputSelect from "../../../Components/Input/InputSelect";
import TitlePage from "../../../Components/TitlePage";
import { Container } from "@mui/material";
import InputLoc from "../../../Components/Input/InputLoc";
import { kecamatans, kelurahans, tpss } from "../../../Config/constanta";

export default function DetailPengguna() {
  const { id } = useParams();
  const optTps = tpss();
  const [state, action] = useUserStore();
  const [input, setInput] = useState({
    name: "",
    username: "",
    password: "",
    role: "",
    phone: "",
    tim: "",
  });
  const [target, setTarget] = useState("");
  const [progres, setProgres] = useState("");
  const [kec, setKec] = useState({ code: "", label: "" });
  const [kel, setKel] = useState({ code: "", label: "", kec: "" });
  const [rt, setRt] = useState({ code: "", label: "" });
  const [rw, setRw] = useState({ code: "", label: "" });

  const [tps, setTps] = useState({ code: "", label: "" });

  const handleChangeFilter = (key1, key2, value, reason) => {
    if (reason === "clear") {
      key1(key2);
    } else {
      key1(value);
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  const loadTarget = async () => {
    const targetId = await action.loadTarget(id);

    if (targetId) setTarget(targetId?.Mastertarget?.target ?? "");
  };

  const loadProgres = async () => {
    const progresId = await action.loadProgres(id);
    if (progresId) setProgres(progresId ?? "");
  };

  const loadData = async () => {
    const res = await action.loadDetail(id);

    if (res) {
      setInput({
        name: res.name ?? "",
        username: res.username ?? "",
        role: res.role ?? "",
        phone: res.phone ?? "",
        tim: res.tim ?? "",
        target: res.Mastertarget?.target ?? 0,
      });
      setKec(kecamatans.find(({ code }) => code == res.kodeKecamatan));
      setKel(kelurahans.find(({ code }) => code == res.kodeKelurahan));
      setRw(optTps.find(({ code }) => code == res.kodeRw));
      setRt(optTps.find(({ code }) => code == res.kodeRt));
    }
  };

  useEffect(() => {
    loadData();
    // loadTarget();
    loadProgres();
  }, []);

  return (
    <Paper sx={{ borderRadius: "20px" }}>
      <TitlePage title="Detail Pengguna" to="/loged/pengguna" />
      <Divider />
      <Container>
        <div style={{ padding: "20px" }}>
          <form>
            <Stack spacing={2} mt="20px">
              <TextInput
                label="Nama"
                onChange={onChangeInput}
                name="name"
                value={input.name}
                disabled={true}
              />
              <TextInput
                label="Username"
                onChange={onChangeInput}
                name="username"
                value={input.username}
                disabled={true}
              />
              <TextInput
                label="Nomor Hp"
                onChange={onChangeInput}
                name="phone"
                value={input.phone}
                disabled={true}
              />
              <InputSelect
                label="Role"
                onChange={onChangeInput}
                name="role"
                value={input.role}
                options={[
                  "superadmin",
                  
                  "relawanrt",
                ]}
                disabled={true}
              />
              {input.role.includes("suk") && (
                <Stack spacing={2}>
                  <TextInput
                    label="Tim"
                    onChange={onChangeInput}
                    name="tim"
                    value={input.tim}
                    disabled={true}
                  />
                  <TextInput
                    label="Target"
                    onChange={onChangeInput}
                    name="target"
                    type="number"
                    value={input.target}
                    disabled={true}
                  />
                  <TextInput
                    label="Progres"
                    type="number"
                    value={progres}
                    disabled={true}
                  />
                </Stack>
              )}
            
              {input.role === "relawanrt" && (
                <Stack spacing={2}>
                   <InputLoc
                  label="Kecamatan"
                  onChange={(event, newValue, reason) => {
                    handleChangeFilter(
                      setKec,
                      { code: "", label: "" },
                      newValue,
                      reason
                    );
                  }}
                  value={kec}
                  options={kecamatans}
                  disabled={true}
                />
                  <InputLoc
                      label="Kelurahan"
                      onChange={(event, newValue, reason) => {
                        handleChangeFilter(
                          setKel,
                          { code: "", label: "", kel: "" },
                          newValue,
                          reason
                        );
                      }}
                      value={kel}
                      options={
                        kec.code
                          ? kelurahans.filter((e) => e.kec == kec.code)
                          : []
                      }
                      disabled={true}
                    />
                  <InputLoc
                    label="RW"
                    onChange={(event, newValue, reason) => {
                      handleChangeFilter(
                        setRw,
                        { code: "", label: "" },
                        newValue,
                        reason
                      );
                    }}
                    value={rw}
                    options={optTps}
                    disabled={true}
                  />
                  <InputLoc
                    label="RT"
                    onChange={(event, newValue, reason) => {
                      handleChangeFilter(
                        setRt,
                        { code: "", label: "" },
                        newValue,
                        reason
                      );
                    }}
                    value={rt}
                    options={optTps}
                    disabled={true}
                  />
                </Stack>
              )}
              {input.role === "sukrw" && (
                <InputLoc
                  label="RW"
                  onChange={(event, newValue, reason) => {
                    handleChangeFilter(
                      setRw,
                      { code: "", label: "" },
                      newValue,
                      reason
                    );
                  }}
                  value={rw}
                  options={optTps}
                  disabled={true}
                />
              )}
              {input.role === "suktps" && (
                <InputLoc
                  label="TPS"
                  value={tps}
                  options={optTps}
                  disabled={true}
                />
              )}
            </Stack>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
