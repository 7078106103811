/* eslint-disable no-unused-vars */
import { createStore, createHook } from "react-sweet-state";
// eslint-disable-next-line no-unused-vars
import {
  viewApi,
  addApi,
  deleteApi,
  detailApi,
  editApi,
  gantiPassApi,
  progresApi,
  targetApi,
} from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    limit: 10,
    skip: 0,
    search: "",
    kodeKecamatan: "",
    kodeKelurahan: "",
    kecamatan: "",
    kelurahan: "",
    tps: "",
    kodeTps: "",
    rt: "",
    rw: "",
    kodeRt: "",
    kodeRw: "",
    role: "superadmin",
    data: null,
    total: 0,
    error: null,
    page: 0,
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    loadData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        const {
          search,
          page,
          kodeKecamatan,
          kodeKelurahan,
          role,
          tps,
          kodeTps,
          kodeRt
        } = getState();
        const resp = await viewApi({
          search,
          page,
          kodeKecamatan,
          kodeKelurahan,
          kodeTps,
          kodeRt,
          role,
        });
        if (resp.data && resp.data.code === 200) {
          setState({
            data: resp.data.payload.result,
            total: resp.data.payload.totalPage,
            page: resp.data.payload.page,
          });
        }
        setState({ isLoading: false });
      },
    loadDetail:
      (id) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        const resp = await detailApi(id);
        if (resp.data && resp.status === 200) {
          return resp.data.payload;
        } else {
          return null;
        }
      },
    loadTarget:
      (id) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        const resp = await targetApi(id);
        if (resp.data && resp.status === 200) {
          return resp.data.payload;
        } else {
          return null;
        }
      },
    loadProgres:
      (id) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        const resp = await progresApi(id);
        if (resp.data && resp.status === 200) {
          return resp.data.payload;
        } else {
          return null;
        }
      },
    addData:
      (form) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await addApi(form);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
    editData:
      (id, form) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await editApi(id, form);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
    gantiPass:
      (id, form) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await gantiPassApi(id, form);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
    deleteData:
      (id) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await deleteApi(id);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
  },
});

export const useUserStore = createHook(Store);
